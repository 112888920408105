
require('./bootstrap');

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

window.Vue = require('vue');

Vue.use(BootstrapVue);

Vue.component('datatables-all', require('./components/DataTable.vue').default);
Vue.component('piechart', require('./components/PieChart.vue').default);


const app = new Vue({
    el: '#app',
});