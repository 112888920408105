<template>   
    <b-container fluid>
        <h2 class="roboto-con txt-scam py-5">Municipios SCAM Certificados</h2>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="region">Región</label>
                <b-form-select 
                    v-model="form.region" 
                    :options="regions" 
                    size="md" 
                    class="form-control"
                    value-field="tag"
                    text-field="label"
                    @change="getCommunes()"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled selected>-- Seleccionar Región --</b-form-select-option>
                    </template>
                </b-form-select>
            </div>
            <div class="form-group col-md-6">
                <label for="commune">Comuna</label>
                <b-form-select 
                    v-model="form.commune" 
                    :options="communes" 
                    size="md" 
                    class="form-control"
                    value-field="tag"
                    text-field="label">
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled selected>-- Seleccionar Comuna --</b-form-select-option>
                    </template>
                </b-form-select>
            </div>
            <div class="form-group col-md-4 pt-4 mt-2 ">
                <button type="reset" class="btn btn btn-dark text-white btn-secondary">Limpiar</button> 
                <button 
                    type="submit" 
                    class="btn btn btn-success btn-secondary"
                    @click="getDataSearch()"
                    >Buscar</button>
            </div>
        </div>

    <h2 class="roboto-con txt-scam py-5">Resultado</h2>

        <b-row>
            <b-col sm="5" md="6" class="my-1">
                <b-form-group
                    label="Mostrar"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="2"
                    label-size="md"
                    label-for="perPageSelect"
                    class="mb-0"
                    >
                    <b-row>
                        <b-col md="4">
                            <b-form-select
                                v-model="perPage"
                                id="perPageSelect"
                                size="md"
                                :options="pageOptions"
                            ></b-form-select>
                        </b-col>
                        <b-col md=4 class="pl-0 pt-2">
                            Registros
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-col>

            <b-col sm="5" md="6" class="my-1">
                <b-form-group
                    label="Filter"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="md"
                    label-for="filterInput"
                    class="mb-0"
                    >
                    <b-input-group size="md">
                        <b-form-input
                          v-model="filter"
                          type="search"
                          id="filterInput"
                          placeholder="Buscador..."
                        ></b-form-input>
                        
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Limpiar</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>

        <b-table
            show-empty
            bordered
            stacked="md"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            empty-filtered-text="No hay registros que coincidan con su solicitud."
            empty-text="No hay registros para mostrar."
          >
			<template v-slot:cell(index)="data">
				{{ data.index + 1 }}
			</template>
            <template v-slot:cell(actions)="data">
                <div class="text-center">
                    <a :href="getUrl(data.item)" class="btn btn-dark btn-sm">
                        <em class="fa fa-eye"></em>
                    </a>
                </div>
            </template>

        </b-table>

        <b-row>
            <b-col md="6" class="pt-3">
                Total {{ totalRows }} registros  
            </b-col>
            
            <b-col md="6" class="my-1">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="md"
                    class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>

    </b-container>
</template>

<script>
  export default {
    props: [
        'datas',
        'communesall',
        'regionsall',
        'camps',
        'class-urls-data',

    ],
    data() {
        return {
            items: [],
            fields: [
                { key: 'index', label: '#' },
                { 
                    key: 'region_tag', 
                    label: 'Región',
                    formatter: (value, key, item) => {
                        return item.region.label
                    },
                },
                { 
                    key: 'commune_tag', 
                    label: 'Municipalidades',
                    formatter: (value, key, item) => {
                        return item.commune.label
                    },
                },
                { key: 'actions', label: 'Acciones' },
            ],
            classUrlsDatas: [],
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            form: {
                region:null,
                commune:null
            },
            regions: [],
            communes: [],
        }
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },
        
    },
    mounted() {       
        this.regions = this.regionsall
        this.communes = this.communesall

    },
    methods: {
        getUrl(item){
            return 'portal/'+ item.region_tag +'/municipalidad/'+ item.commune_tag +'/'
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        async getCommunes(){

            this.form.commune = null
               
            let url = "/api/v1/region-communes/"+ this.form.region
            const response = await axios.get(url)

            this.communes = response.data
        },
        async getDataSearch(){
            
            let url = "/api/v1/busqueda/"
            const response = await axios.get(url, { params : this.form})

            this.items = response.data

        }
    }
  }
</script>
